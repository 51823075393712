import * as microsoftTeams from "@microsoft/teams-js";
import * as Sentry from "@sentry/browser";

document.addEventListener("turbolinks:load", async function(event) {
  // --------------- [ Helper Methods ] --------------- //

  var userToken = function() {
    return window.localStorage.getItem("userToken");
  }

  var tokenData = function(token) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  var isTokenValid = function(token) {
    if(token === null) return false;

    var expiry = tokenData(token).exp;
    return (Math.floor((new Date).getTime() / 1000)) < expiry;
  }

  var isTokenForUser = function (token, context) {
    var payload = parseJwt(token)

    return context.user.id === payload.teams_id
  }

  var isMicrosoftTeams = function() {
    return $('body.ms-teams').length > 0;
  }

  var isSignInPage = function() {
    return document.body.classList.contains("integrations/microsoft_teams/channels");
  }

  var isLogout = function () {
    return $('body.logout').length > 0;
  }

  var inIframe = function() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  var isAuthEnd = function() {
    return $('body.auth_end').length > 0;
  }

  var isAuthStart = function() {
    return $('body.auth_start').length > 0;
  }

  var hideAllRenders = function() {
    $('#pre-login').addClass('d-none');
    $('#manual-login').addClass('d-none');
    $('#error-message').addClass('d-none');

    // hide the back button
    // will be added back on new page load
    $('#top-back-btn').addClass('d-none');
  }

  var renderLoginPrompt = function() {
    hideAllRenders();

    // show button and ask user to sign in with prompt
    $('#manual-login').removeClass('d-none');
  }

  var renderError = function() {
    hideAllRenders();

    sendLoginEvent('login_failed')

    // show error message with button to reload the page
    $('#error-message').removeClass('d-none');
  }

  // var localStorageKeyBase = function() {
  //   return gon.channelId + "/";
  // }

  // var reloadPage = function(reason = "Login") {
  //
  //   // handle redirect loop.
  //   // happens when 3rd party cookies are not allowed in browser.
  //   // we login the user but we can't access the cookies in next request
  //   // and that means that we can't know if the user is logged in or not.
  //
  //   var key = localStorageKeyBase() + "reloadAttempts" + reason;
  //
  //   var attempts = window.localStorage.getItem(key);
  //   if(attempts) {
  //     window.localStorage.setItem(key, ++attempts);
  //   } else {
  //     window.localStorage.setItem(key, 0);
  //   }
  //
  //   if(attempts > 3) {
  //     renderError();
  //     localStorage.removeItem(key);
  //     localStorage.removeItem('userToken');
  //   } else {
  //     var url = new URL(window.location);
  //
  //     // add ?reload=true in the end so the controller can handle taking user
  //     // to the last page they were on
  //     // mainly for asking additional permissions view.
  //     var urlParams = url.searchParams;
  //     urlParams.set('reload', true);
  //     urlParams.set('user_token', userToken());
  //     url.search = urlParams.toString();
  //
  //     Turbolinks.visit(url.toString());
  //   }
  // }

  var isConfigPage = function() {
    return isMicrosoftTeams() && $('body.configuration').length > 0;
  }

  var navigateToDeepLink = function() {
    var key = 'deep_link_visited';
    var deepLinkVisited = window.localStorage.getItem(key);

    microsoftTeams.app.getContext().then(function(context) {
      // if we have a sub entity, we have not visited it yet in this click time
      if(context.subEntityId && deepLinkVisited != context.userClickTime) {
        window.localStorage.setItem(key, context.userClickTime);
        Turbolinks.visit(context.subEntityId);
      }
    })
  }

  var parseJwt = function (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }


  // --------------- [ Initialize ] --------------- //


  try {
    await microsoftTeams.app.initialize();
  } catch (error) {
      console.error(error)
  }
  if(!isConfigPage()) navigateToDeepLink();

  // --------------- [ Config Page ] --------------- //

  var configPage = function() {

    microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
      microsoftTeams.pages.config.setConfig({
        websiteUrl: gon.contentUrl,
        contentUrl: gon.contentUrl,
        entityId: "assignmentsTab",
        suggestedDisplayName: "Nurture"
      });

      saveEvent.notifySuccess();
    });

    microsoftTeams.pages.config.setValidityState(true);
  }

  if(isConfigPage()) configPage();


  // --------------- [ Authentication ] --------------- //

  var loginStartPath = function(adminconsent = false, tenantId = gon.tenantId) {
    var url = '/i/teams/' + tenantId + '/auth/start?';

    if(adminconsent) {
      url += 'adminconsent=true&'
    }

    return url
  }

  var redirectToReactApp = function(token) {
    $.ajax({
      type: "POST",
      url: '/i/teams/' + gon.tenantId + '/auth/react_app_url',
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data: {
        channelId: gon.channelId || window.localStorage.getItem("channelId"),
      },
      dataType: "json",
      success: function(data) {
        window.localStorage.setItem("userToken", token);
        window.location.replace(data.redirect_url)
      },
      error: function(jqXHR, textStatus, errorThrown) {
        const errorMessage = jqXHR.responseJSON ? jqXHR.responseJSON.message : errorThrown;

        renderError();
        console.error(errorMessage)
        if (Sentry.getCurrentHub().getClient() !== undefined) {
          Sentry.captureException(`Error getting react app url, tenantID: ${gon.tenantId}, channelID: ${gon.channelId}, reason: ${errorMessage}`);
        }
      }
    });
  }

  var sendLoginEvent = function(event) {
    $.ajax({
      type: "POST",
      url: '/api/v1/metrics/track',
      data: {
        metric: event
      },
      dataType: "json",
      success: function(data) {
      },
      error: function(error) {
        if (Sentry.getCurrentHub().getClient() !== undefined) {
          Sentry.captureException(`Error getting new token, channelID: ${gon.channelId}, reason: ${error.message}`);
        }
      }
    });
  }

  // ---------- Manual login flow

  var manualLogin = async function(adminconsent = false) {

    if(!inIframe()) {
      // via browser
      Turbolinks.visit(loginStartPath(adminconsent));
      return;
    }

    // reset session so we can login again
    window.localStorage.removeItem("userToken");

    microsoftTeams.app.getContext().then(function(context) {
      microsoftTeams.authentication.authenticate({
        url: loginStartPath(adminconsent, context.tid) + 'popup=true',
        width: 600,
        height: 535,
      }).then(function(result) {
        window.localStorage.setItem("userToken", result);
        sendLoginEvent('login_success');
        redirectToReactApp(result);
      }).catch(function(reason) {
        renderError();
      })
    });
  }

  $('.manual-login').click(function(e){
    e.preventDefault();
    manualLogin();
  });

  $('.try-again').click(function(e){
    e.preventDefault();
    startSignInFlow();
  });

  $('.manual-admin-login').click(function(e){
    e.preventDefault();
    manualLogin(true);
  });


  // End Auth

  // For React JS front-end to get userToken via popup
  var postMessageForPopupLogin = function() {
    var allowedOrigins = ['ms-teams.gonurture.com', 'ms-teams-staging.gonurture.com'];

    var originDomain = window.localStorage.getItem("popupLoginOrigin");

    // for development allow all origins
    // demo: https://jsfiddle.net/p46qa12L/21/
    if(gon.env == "development") allowedOrigins.push(originDomain);

    if(originDomain && allowedOrigins.includes(originDomain) && window.opener) {
      window.opener.postMessage(
        {
          "userSignedIn": gon.userSignedIn,
          "userToken": gon.userToken
        },
        "https://" + originDomain
      );
    }
  }

  if(isMicrosoftTeams() && isAuthEnd()) {
    postMessageForPopupLogin();

    try {
      if (gon.userSignedIn) microsoftTeams.authentication.notifySuccess(gon.userToken);
      if (!gon.userSignedIn) microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
    } catch (e) {
      // This block should be the browser
      if (gon.userToken && gon.userSignedIn) {
        redirectToReactApp(gon.userToken);
        sendLoginEvent('login_success')
      } else {
        console.error(e.message)
        renderError();
      }
    }
  }

  // Start Auth

  var storePopupLoginSource = function() {
    var originDomain = new URL(window.location).searchParams.get("origin");
    window.localStorage.setItem("popupLoginOrigin", originDomain);
  }

  if(isMicrosoftTeams() && isAuthStart()) {
    storePopupLoginSource();

    // auto click the login button to make POST request
    // https://stackoverflow.com/a/45002658
    // jquery_ujs instead of Rails package
    var link = $('#login-with-microsoft')[0];
    $.rails.fire(link, 'submit');
    link.click();
  }


  // ---------- Auto SSO login

  var startSignInFlow = function(fromLogout = false) {

    if(!inIframe() && !fromLogout) {
      // via browser
      Turbolinks.visit(loginStartPath());
      return;
    }

    renderLoginPrompt();
  }

  // --------------- [ Logout Page ] --------------- //
  if (isLogout()) {
    window.localStorage.removeItem("userToken");
    window.localStorage.setItem('channelId', gon.channelId);
    startSignInFlow(true);
    return;
  }

  // ---------- Entry Point In teams app
  if(isMicrosoftTeams() && isSignInPage()) {
    microsoftTeams.app.getContext().then(function(context) {
      var token = userToken();
      if (token && isTokenValid(token) && isTokenForUser(token, context)) {
        redirectToReactApp(token, context.tid);
      } else {
        window.localStorage.setItem('channelId', gon.channelId);
        startSignInFlow();
      }
    });
  }

  // ------------ Entry Point In Browser
  if(!inIframe() && isSignInPage()) {
    var token = userToken();
    if (token && isTokenValid(token)) {
      redirectToReactApp(token);
    } else {
      window.localStorage.setItem('channelId', gon.channelId);
      startSignInFlow();
    }
  }

  // once a successful login, clear the attempts
  // if(isMicrosoftTeams() && gon.clearReloadAttempts) {
  //   // when successful index page load, clear so we don't use this for the next
  //   // time the user visits the tab again where the attempts keep increasing
  //   // and eventually get the error message when there is no error.
  //   window.localStorage.setItem(localStorageKeyBase() + "reloadAttemptsLogin", 0);
  // }


  // --------------- [ Token Security Assistance ] --------------- //

  // Does not prevent anything 100% but might help.
  // Copying address bar URL or right-clicking and copying a link's address
  // will include the user_token which can be used by another user or browser
  // to act as the user the token belongs to till the token expires (1 hour max)

  // commenting out the code below for development would be helpful

  if(isMicrosoftTeams()) {

    // remove user_token from the address bar
    // replaces all url params but does not reload
    // don't do on the sign in page since we need this to compare auth
    if(!isSignInPage()) {
      var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({path: newUrl}, '', newUrl);
    }


    // if inside Teams app, check if the user who is signed in on MS Teams
    // is the user who is using our app. If not, start new login flow.
    // if(inIframe() && gon.userSignedIn && gon.azureUserObjectId) {
    //   microsoftTeams.getContext(function(context) {
    //     if(gon.azureUserObjectId != context.userObjectId) {
    //       localStorage.removeItem('userToken');
    //       gon.azureUserObjectId = null;
    //
    //       if (Sentry.getCurrentHub().getClient() !== undefined) {
    //         Sentry.captureException(`Teams user is different from session user, channelID: ${gon.channelId}, teamsUser: ${context.userObjectId}, sessionUser: ${gon.azureUserObjectId}`);
    //       }
    //       Turbolinks.visit("/i/teams/" + context.tid + "/" + gon.channelId);
    //     }
    //   });
    // }


    // Prevent right clicking on links - mainly to copy link URL?
    document.addEventListener("contextmenu", function(e){
      if (e.target.nodeName === "A") {
        e.preventDefault();
      }
    }, false);

  }


});
